import { useState } from "react";
import axios from "axios";
import Loading from "./Loading";
import MessageContent from "./MessageContent";
import UniqueID from "./UniqueID";
// import { nanoid } from 'nanoid';


let history = '';

export default function TextInput({ setMessages, messages }) {

  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // const sessionId = <UniqueID />;
  const sessionId = sessionStorage.getItem("mySessionKey"); 

  // function StringAppender({ originalString, appendString }) {
  //   Function to append two strings and return the result as a string
  //   const appendToString = () => {
  //     return originalString + appendString;
  //   };
  
  //   Call the appendToString function to obtain the result
  //   const appendedString = appendToString();
  //   return appendedString;
  // }

  // const originalString = value;
  // const appendString = 'World!';
  // const history = <StringAppender originalString ={originalString} appendString={appendString} />
  // let history = StringAppender (originalString, appendString)

 
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("value : "+value);
    // console.log("history : "+history);
    submitData();
  };

  
  history = sessionStorage.getItem("chatHistory");
  // console.log(history);
  
  const submitData = async () => {
    // if (history === null || history === "") {
    //   history = value;
    // } else {
    //   history = history;
    // }

    //ORIGINAL
    // let payload = {
    //   prompt: value
    // };
    let payload = {
      sessionInfo: sessionId,
      text: value,
    };
  // console.log(sessionId);

    // {
    //   "text": "bagaimana cara isi token PLN?",
    //   "sessionInfo": {"session": "1"}
    // }




    setValue("");

    setIsLoading(true);
    setMessages([{ status: "sender", content: value }, ...messages]);
    setMessages(prevMessages => [{ status: "receiver", content: <Loading /> }, ...prevMessages])

    let endpoint = '';
    endpoint = 'https://asia-southeast1-mii-telkomsel-genai.cloudfunctions.net/chatbison-digipos-1';
    // endpoint = 'https://asia-southeast2-mii-telkomsel-genai.cloudfunctions.net/chatbison-digipos';
    // endpoint = 'https://asia-southeast2-mii-telkomsel-genai.cloudfunctions.net/moana-model';
    //endpoint = 'https://asia-southeast2-mii-telkomsel-genai.cloudfunctions.net/moana-demo';
    // const path_gs = 'gs://';

    try {
      const res = await axios.post(endpoint, payload);
      // console.log(res);
      const responseMessage = res.data.text;

      // const link1 = res.data.source_document;
      // let newlink1 = '';
      // newlink1 = link1.replaceJSX(path_gs, '')[2];

      // history = value+"\n"+responseMessage+"\n"+history;
      // sessionStorage.setItem("chatHistory", history);
      // console.log(history);
      

      if (responseMessage) {
        setMessages(prevMessages => [
          {
            status: "receiver",
            content: <MessageContent text={responseMessage} />,
          },
          ...prevMessages.slice(1),
        ]);
      } else {
        console.error("Tidak ada data untuk menjawab ini.");
        setMessages(prevMessages => prevMessages.slice(1));
      }

    } catch (error) {
      console.error(error);
      setMessages(prevMessages => prevMessages.slice(1));
    }
    setIsLoading(false);
  };

  return (

    <form
      name="displayanswers" onSubmit={handleSubmit}
      className="font-Arial flex flex-row rounded-3xl border border-black shadow-grey shadow-md w-full bg-secondary-light h-16 pr-8 items-center space-x-2 dark:bg-secondary-dark dark:shadow-none"
    >
      <input
        type="text"
        name="input"
        value={value}
        onChange={(e) => { setValue(e.target.value); }}
        className="font-Arial bg-secondary-light w-full rounded-3xl focus:ring-0 focus:border-none focus:outline-none h-full p-2 dark:bg-secondary-dark"
        autoComplete="off"
        placeholder="Type your question here...." />


    </form>
  );

}
